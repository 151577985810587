import React, { useState, useEffect } from "react";
import URL from "../url";
import "./recipeeditor.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdAddCircleOutline } from "react-icons/md";

const DropdownExample = ({ selectedOption, onSelectChange }) => {
  return (
    <div>
      <select
        className="dropdown"
        id="dropdown"
        value={selectedOption || "g"}
        onChange={onSelectChange}
      >
        <option value="g">g</option>
        <option value="l">l</option>
        <option value="x">x</option>
        <option value="kg">kg</option>
        <option value="Tbsp">Tbsp</option>
        <option value="Tsp">Tsp</option>
        <option value=""></option>
      </select>
    </div>
  );
};

const RecipeEditor = ({
  recipeId,
  initialDishName,
  initialIngredients,
  onClose,
  onUpdate,
}) => {
  const [newDishName, setNewDishName] = useState(initialDishName);
  const [newIngredients, setNewIngredients] = useState(initialIngredients);
  const [newIngredientForms, setNewIngredientForms] = useState([]);
  const [token, setToken] = useState("");


  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token || "");
  }, []);

  const handleUpdateRecipe = async () => {
    const combinedIngredients = [...newIngredients, ...newIngredientForms];

    try {
      const response = await fetch(`http://${URL.code}:5001/recipe/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          _id: recipeId,
          newDishName,
          newIngredients: combinedIngredients,
        }),
      });

      if (response.ok) {
        console.log("Recipe updated successfully");
        setNewIngredients(combinedIngredients);
        setNewIngredientForms([]);
        onUpdate({
          _id: recipeId,
          dishName: newDishName,
          ingredients: combinedIngredients,
        });
        onClose();
      } else {
        console.error("Failed to update recipe");

      }
    } catch (error) {
      console.error("Error:", error);

    }
  };

  const handleEditIngredient = (index, editedIngredient) => {
    setNewIngredients((prevIngredients) =>
      prevIngredients.map((ingredient, i) =>
        i === index ? editedIngredient : ingredient
      )
    );
  };

  const handleDeleteIngredient = async (ingredientId) => {
    try {
      const response = await fetch(
        `http://${URL.code}:5001/recipe/deleteIngredient/${recipeId}/${ingredientId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("Ingredient deleted successfully");
      } else {
        console.error("Failed to delete ingredient");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectChange = (e, index, isForm) => {
    const { value } = e.target;
    if (isForm) {
      setNewIngredientForms((prevForms) =>
        prevForms.map((form, i) =>
          i === index ? { ...form, unit: value } : form
        )
      );
    } else {
      setNewIngredients((prevIngredients) =>
        prevIngredients.map((ingredient, i) =>
          i === index ? { ...ingredient, unit: value } : ingredient
        )
      );
    }
  };

  const handleAddNewIngredientForm = () => {
    setNewIngredientForms((prevForms) => [
      ...prevForms,
      { name: "", amount: "", unit: "g" },
    ]);
  };

  const handleNewIngredientChange = (index, field, value) => {
    setNewIngredientForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index ? { ...form, [field]: value } : form
      )
    );
  };

  return (
    <div className="recipe__editor">
      <input
        className="input__dish-name"
        type="text"
        value={newDishName}
        onChange={(e) => setNewDishName(e.target.value)}
      />

      <ul>
        {newIngredients.map((ingredient, index) => (
          <li className="editor__box" key={ingredient._id || index}>
            <input
              className="input__ingredient"
              value={ingredient.name}
              onChange={(e) =>
                handleEditIngredient(index, {
                  ...ingredient,
                  name: e.target.value,
                })
              }
            />
            <input
              type="number"
              className="input__number"
              value={ingredient.amount}
              onChange={(e) =>
                handleEditIngredient(index, {
                  ...ingredient,
                  amount: e.target.value,
                })
              }
            />
            <DropdownExample
              selectedOption={ingredient.unit}
              onSelectChange={(e) => handleSelectChange(e, index, false)}
            />
            <button
              className="button__editor"
              onClick={() => handleDeleteIngredient(ingredient._id)}
            >
              <RiDeleteBin6Line />
            </button>
          </li>
        ))}
      </ul>

      {newIngredientForms.map((ingredient, index) => (
        <div className="editor__box add" key={index}>
          <input
            type="text"
            className="input__ingredient"
            value={ingredient.name}
            onChange={(e) =>
              handleNewIngredientChange(index, "name", e.target.value)
            }
          />
          <input
            type="number"
            className="input__number"
            value={ingredient.amount}
            onChange={(e) =>
              handleNewIngredientChange(index, "amount", e.target.value)
            }
          />
          <DropdownExample
            selectedOption={ingredient.unit}
            onSelectChange={(e) => handleSelectChange(e, index, true)}
          />
        </div>
      ))}

      <button className="button__editor" onClick={handleAddNewIngredientForm}>
        <MdAddCircleOutline />
      </button>

      <div className="buttons__editor">
        <button className="recipe__box__button" onClick={handleUpdateRecipe}>
          Update
        </button>
        <button className="recipe__box__button" onClick={onClose}>
          CLOSE
        </button>
      </div>
      
    </div>
  );
};

export default RecipeEditor;
