import React from 'react';
import URL from '../url';

const deleteRecipe = async (id) => {
  try {
    const response = await fetch(`http://${URL.code}:5001/recipe/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ _id: id }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Data deleted:', data);
  } catch (error) {
    console.error('Error deleting data:', error);
  }
};

function DeleteButton({ id, onDelete }) {
  const handleDelete = async () => {
    try {
      await deleteRecipe(id);
      onDelete();
    } catch (error) {
      console.error('Error handling deletion:', error);
    }
  };

  return (
    <button className='recipe__box__button' onClick={handleDelete}>DELETE</button>
  );
}

export default DeleteButton;
