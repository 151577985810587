import './home.css'
import Header from '../components/Header'
// import Recipes from '../components/Recipes'
import SearchRecipes from '../components/SearchRecipes'

const Home = () => {
    return (
        <div>
            <Header/>
            <SearchRecipes/>

        </div>
    )
}
export default Home